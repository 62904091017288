import { useEffect, useState } from "react";
import { getDaysHrsMinsAndSecs } from "utils/helper";
import OptionSelect from "components/ui/SelectUI/OptionSelect/OptionSelect";

const ScheduleDayInput = ({
    showLabel,
    label,
    showSubLabel = true,
    subLabel,
    error,
    maxDays,
    handleDateChange,
    timeValue,
    showSecondsInput = true,
    leftColumnClass = "col-md-8",
    rightColumnClass = "col-md-4",
}) => {
    const [usingDays, setUsingDays] = useState(false);
    const [noOfDays, updateNoOfDays] = useState();
    const [noOfHours, updateNoOfHours] = useState();
    const [noOfMins, updateNoOfMins] = useState();
    const [noOfSecs, updateNoOfSec] = useState();

    const handleSwitchOption = (e) => {
        setUsingDays(!usingDays);
        // updateNoOfDays();
        // updateNoOfHours(0);
        // updateNoOfMins(0);
        // updateNoOfSec(0);
    };

    const calTotalSeconds = (timeValue, timeType) => {
        if (timeType === "DD") {
            return timeValue * 60 * 60 * 24;
        } else if (timeType === "HH") {
            return timeValue * 60 * 60;
        } else if (timeType === "MM") {
            return timeValue * 60;
        } else {
            return Number(timeValue);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        switch (name) {
            case "DD":
                return updateNoOfDays(value);
            case "HH":
                return updateNoOfHours(value);
            case "MM":
                return updateNoOfMins(value);
            case "SS":
                return updateNoOfSec(value);
            default:
                return "";
        }
    };

    useEffect(() => {
        let totalNoOfSeconds =
            calTotalSeconds(noOfDays ? noOfDays : 0, "DD") +
            calTotalSeconds(noOfHours ? noOfHours : 0, "HH") +
            calTotalSeconds(noOfMins ? noOfMins : 0, "MM") +
            calTotalSeconds(noOfSecs ? noOfSecs : 0, "SS");

        handleDateChange?.(
            totalNoOfSeconds,
            noOfDays,
            noOfHours,
            noOfMins,
            noOfSecs
        );
        // eslint-disable-next-line
    }, [noOfDays, noOfHours, noOfMins, noOfSecs]);

    const handleInitialValues = () => {
        if (timeValue > 0) {
            let { days, hours, mins, secs } = getDaysHrsMinsAndSecs(timeValue);

            updateNoOfDays(days);
            updateNoOfHours(hours);
            updateNoOfMins(mins);
            updateNoOfSec(secs);
            setUsingDays(days > 0 ? true : false);
        }
    };

    useEffect(() => {
        handleInitialValues();
        // eslint-disable-next-line
    }, [timeValue]);
    return (
        <>
            <div className='form-group mb-3'>
                {showLabel && (
                    <label className='form-label schedule__input__label fw-medium '>
                        {label}
                    </label>
                )}
                {showSubLabel && <p className='sub-label'>{subLabel}</p>}
                <div className='row'>
                    <div className={`${leftColumnClass} pe-0`}>
                        <div
                            className={`schedule__input ${
                                error ? "schedule__error" : ""
                            }`}>
                            {usingDays ? (
                                <input
                                    type='number'
                                    name='DD'
                                    min={0}
                                    max={365}
                                    key='DD'
                                    placeholder={"DD"}
                                    // value={noOfDays}
                                    defaultValue={noOfDays}
                                    maxLength={2}
                                    onChange={handleInputChange}
                                />
                            ) : (
                                <>
                                    <OptionSelect
                                        options={Array.from(
                                            Array(25),
                                            (e, i) => ({
                                                label: i,
                                                value: i,
                                            })
                                        )}
                                        placeholder='HH'
                                        name='HH'
                                        value={
                                            noOfHours > 0 && {
                                                label: noOfHours,
                                                value: noOfHours,
                                            }
                                        }
                                        handleChange={(name, value) =>
                                            handleInputChange({
                                                target: { name, value },
                                            })
                                        }
                                    />
                                    <OptionSelect
                                        options={Array.from(
                                            Array(60),
                                            (e, i) => ({
                                                label: i,
                                                value: i,
                                            })
                                        )}
                                        name='MM'
                                        placeholder='MM'
                                        value={
                                            noOfMins > 0 && {
                                                label: noOfMins,
                                                value: noOfMins,
                                            }
                                        }
                                        handleChange={(name, value) =>
                                            handleInputChange({
                                                target: { name, value },
                                            })
                                        }
                                    />
                                    {showSecondsInput && (
                                        <OptionSelect
                                            options={Array.from(
                                                Array(60),
                                                (e, i) => ({
                                                    label: i,
                                                    value: i,
                                                })
                                            )}
                                            name='SS'
                                            placeholder='SS'
                                            value={
                                                noOfSecs > 0 && {
                                                    label: noOfSecs,
                                                    value: noOfSecs,
                                                }
                                            }
                                            handleChange={(name, value) =>
                                                handleInputChange({
                                                    target: { name, value },
                                                })
                                            }
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                    <div className={`${rightColumnClass}`}>
                        <div className='switch6'>
                            <label className='switch6-light'>
                                <input
                                    type='checkbox'
                                    checked={usingDays}
                                    onChange={handleSwitchOption}
                                />
                                <span>
                                    <span>Hours</span>
                                    <span>Days</span>
                                </span>
                                <a href='/' className='btn btn-active-schedule'>
                                    .
                                </a>
                            </label>
                        </div>
                    </div>
                </div>
                <input
                    name='deliveryInterval'
                    value={timeValue}
                    hidden={true}
                    readOnly={true}
                />
            </div>
        </>
    );
};

export default ScheduleDayInput;
