import { getTimestampDate } from "utils/helper";
import "./MessageTimeStatus.scss";

const MessageTimeStatus = ({ date }) => {
    return (
        <>
            {date && (
                <span className='read-time'>
                    {getTimestampDate(date, "time")}
                </span>
            )}
        </>
    );
};

export default MessageTimeStatus;
