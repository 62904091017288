import CapsuleElement from "components/dashboard/KnowledgeBase/common/Capsule/CapsuleElement/CapsuleElement";
import { useSelector } from "react-redux";

const BranchEmbedCapsule = ({
    agentGuideClassCapsule,
    guideFlowCapsuleClassId,
    guideFlowCapsuleEntryId,
}) => {
    const { capsuleClasses } = useSelector((state) => state.capsule);

    const capsuleClass = capsuleClasses?.find?.(
        ({ capsuleClassId }) => capsuleClassId === guideFlowCapsuleClassId
    );

    return (
        <>
            <CapsuleElement
                capsule={agentGuideClassCapsule || capsuleClass}
                capsuleEntryId={guideFlowCapsuleEntryId}
                useCapsuleStyles={true}
                hasBgColor={true}
                hideDeleteAction={true}
                hideEditAction={true}
                capsuleBottomMenu={true}
            />
        </>
    );
};

export default BranchEmbedCapsule;
