import config from "config/config";
import React, { useEffect, useState, createContext } from "react";
import { io } from "socket.io-client";
import { retriveAccessToken } from "storage/cookieStorage";

export const SocketContext = createContext(null);

export const SocketProvider = (props) => {
  const [socket, setSocket] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const token = retriveAccessToken();

    const createSocket = () => {
      const newSocket = io(config.socketGateway.SOCKET_BASE_URL, {
        extraHeaders: {
          authorization: token,
        },
        reconnection: false, // Disable default reconnection logic
      });

      setSocket(newSocket);

      newSocket.on("connect", () => {
        console.log("Connected to socket");
        setRetryCount(0); // Reset retry count on successful connection
      });

      newSocket.on("disconnect", () => {
        console.log("Socket disconnected");
        if (retryCount < 3 && isOnline) {
          setTimeout(() => {
            console.log("Retrying socket connection...");
            setRetryCount((prev) => prev + 1);
            createSocket(); // Recreate the socket connection
          }, 300000); // Retry every 5 minutes
        }
      });

      newSocket.on("connect_error", (error) => {
        console.error("Socket connection error:", error);
        if (retryCount < 3 && isOnline) {
          setTimeout(() => {
            console.log("Retrying socket connection due to error...");
            setRetryCount((prev) => prev + 1);
            createSocket(); // Recreate the socket connection
          }, 300000); // Retry every 5 minutes
        }
      });

      return newSocket;
    };

    const socketInstance = createSocket();

    return () => {
      socketInstance.close(); // Clean up socket connection on unmount
    };
  }, [retryCount, isOnline]);

  useEffect(() => {
    const handleOnline = () => {
      console.log("Network online");
      setIsOnline(true);
      if (socket?.disconnected && retryCount < 3) {
        setRetryCount((prev) => prev + 1);
        setSocket((prevSocket) => {
          prevSocket?.close(); // Close the old socket before retrying
          return createSocket();
        });
      }
    };

    const handleOffline = () => {
      console.log("Network offline");
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [socket, retryCount]);

  return (
    <SocketContext.Provider value={socket}>
      {props.children}
    </SocketContext.Provider>
  );
};

const createSocket = () => {
  const token = retriveAccessToken();
  return io(config.socketGateway.SOCKET_BASE_URL, {
    extraHeaders: {
      authorization: token,
    },
    reconnection: false, // Disable default reconnection logic
  });
};
