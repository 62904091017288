import React, { useState, useContext, useEffect } from "react";
import EmailInput from "./EmailInput/EmailInput";
import CopyRecipient from "./CopyRecipient/CopyRecipient";
import EmailSubject from "./EmailSubject/EmailSubject";
import EmailActions from "./EmailActions/EmailActions";
import API from "services/lib/api";
import { apiRoute } from "services";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import { emailModalContentType } from "../enum";
// import EmailHolder from "./EmailInput/EmailHolder/EmailHolder";
import { emailTypes } from "../../../ChatTickets/TicketDetails/ExternalEmails/enum";
import EmailContent from "./EmailContent/EmailContent";
import "./SendEmail.scss";
import { EXTERNAL_EMAIL_ACTION } from "components/dashboard/AgentInbox/enum";
import EmailAttachedFiles from "./EmailAttachedFiles/EmailAttachedFiles";
// import EmailAttachedFiles from "./EmailAttachedFiles/EmailAttachedFiles";

const { EMAIL_SUCCESS } = emailModalContentType;
const { ERROR } = dataQueryStatus;
const { SENT, RECEIVED } = emailTypes;

const { PREVIEW_EMAIL, REPLY_EMAIL, SEND_NEW_EMAIL } = EXTERNAL_EMAIL_ACTION;

const SendEmail = ({
    ticketId,
    setContentType,
    emailPreviewValue,
    externalEmail,
    handleSuccess,
    toggleClipboardAndAttachment,
    handleToggleEmailAttachment,
    attachments,
    emailAction,
    setSenderEmailData,
}) => {
    const isResponse = emailAction === REPLY_EMAIL;
    const preview = emailAction === PREVIEW_EMAIL;
    const isNewEmail = emailAction === SEND_NEW_EMAIL;

    const preFilledRequest = {
        ticketId: ticketId,
        message:
            !isResponse || emailPreviewValue?.reply
                ? emailPreviewValue?.message
                : "",
        subject: emailPreviewValue?.subject || "",
        senderEmail: isNewEmail
            ? externalEmail
            : emailPreviewValue?.senderEmail,
        recipientsEmail:
            (preview && !isResponse) || emailPreviewValue?.emailType === SENT
                ? emailPreviewValue?.recipientsEmails
                : emailPreviewValue?.emailType === RECEIVED
                    ? [emailPreviewValue?.senderEmail]
                    : emailPreviewValue?.recipientsEmails || [],
        cc: emailPreviewValue?.cc || [],
        bcc: emailPreviewValue?.bcc || [],
        threadId: emailPreviewValue?.threadId && emailPreviewValue?.threadId,
        messageId: emailPreviewValue?.messageId && emailPreviewValue?.messageId,
        fileAttachments: !isResponse
            ? emailPreviewValue?.fileAttachments || []
            : [],
    };
    const [request, setRequest] = useState(preFilledRequest);
    const [loading, setLoading] = useState(false);
    const [showCopy, setShowCopy] = useState(
        emailPreviewValue?.cc?.length > 0 || false
    );
    const [showBlindCopy, setShowBlindCopy] = useState(
        emailPreviewValue?.bcc?.length > 0 || false
    );
    const toastMessage = useContext(ToastContext);

    const handleChange = ({ target: { name, value } }) => {
        setRequest((prevRequest) => ({ ...prevRequest, [name]: value }));
    };

    const handleRecipientEmail = (email, recipientType) => {
        setRequest((prevRequest) => ({
            ...prevRequest,
            [recipientType]: Array.from(
                new Set([email, ...prevRequest[recipientType]])
            ),
        }));
    };

    const handleRemoveEmail = (email, recipientType) => {
        setRequest((prevRequest) => ({
            ...prevRequest,
            [recipientType]: prevRequest?.[recipientType]?.filter(
                (emailValue) => emailValue !== email
            ),
        }));
    };

    const { ticketAttachment } = attachments;
    useEffect(() => {
        if (ticketAttachment?.text?.length > 0) {
            setRequest((prev) => ({
                ...prev,
                message: `${prev?.message ? prev?.message : ""} ${ticketAttachment?.text
                    }`,
            }));
        }
    }, [ticketAttachment?.text]);

    const sendExternalEmail = async () => {
        try {
            setLoading(true);
            const url = apiRoute.sendExternalEmail;

            const { fileAttachments, ...requestData } = request;
            const { fileAttachments: files } = attachments;
            const requestBody = {
                ...requestData,
                cannedEmailId: emailPreviewValue?.messageId,
                fileAttachments: files?.map(
                    ({ filename, content, attachmentType }) => ({
                        filename,
                        content,
                        attachmentType,
                    })
                ),
            };
            
            const res = await API.post(url, { ...requestBody });

            if (res.status === 201) {
                setLoading(false);
                handleSuccess?.();
                setContentType(EMAIL_SUCCESS);
            }
        } catch (err) {
            setLoading(false);
            const message = getErrorMessage(err);
            toastMessage(message, ERROR);
        }
    };

    useEffect(() => {
        if (emailPreviewValue) {
            setRequest(preFilledRequest);
            setSenderEmailData(preFilledRequest);
        }
        // eslint-disable-next-line
    }, [emailPreviewValue]);

    const { subject, message, recipientsEmail, cc, bcc } = request;

    return (
        <>
            <div className='recipient__container'>
                {/* <div className='recipient'>
                    <span className='recipient--direction'>From: </span>
                    <EmailHolder
                        isSenderEmail={true}
                        email={isNewEmail ? externalEmail : senderEmail}
                        disabled={true}
                    />
                </div> */}
                <div className='recipient'>
                    <span className='recipient--direction'>To: </span>
                    <EmailInput
                        emailList={recipientsEmail}
                        handleRecipientEmail={(email) =>
                            handleRecipientEmail(email, "recipientsEmail")
                        }
                        handleRemoveEmail={(email) =>
                            handleRemoveEmail(email, "recipientsEmail")
                        }
                        disabled={preview}
                    />
                    <div className='copy__recipient--controls'>
                        {!showCopy && (
                            <span onClick={() => setShowCopy(true)}>Cc</span>
                        )}
                        {!showBlindCopy && (
                            <span onClick={() => setShowBlindCopy(true)}>
                                Bcc
                            </span>
                        )}
                    </div>
                </div>
                {showCopy && (
                    <div className='recipient'>
                        <CopyRecipient
                            copyType='Cc'
                            setShowCopy={setShowCopy}
                            emailList={cc}
                            handleRecipientEmail={(email) =>
                                handleRecipientEmail(email, "cc")
                            }
                            handleRemoveEmail={(email) =>
                                handleRemoveEmail(email, "cc")
                            }
                            disabled={preview}
                        />
                    </div>
                )}
                {showBlindCopy && (
                    <div className='recipient'>
                        <CopyRecipient
                            copyType='Bcc'
                            setShowCopy={setShowBlindCopy}
                            emailList={bcc}
                            handleRecipientEmail={(email) =>
                                handleRecipientEmail(email, "bcc")
                            }
                            handleRemoveEmail={(email) =>
                                handleRemoveEmail(email, "bcc")
                            }
                            disabled={preview}
                        />
                    </div>
                )}
            </div>
            <EmailSubject
                emailSubject={subject}
                handleChange={handleChange}
                preview={preview}
            />

            <EmailContent
                message={message}
                handleChange={handleChange}
                isResponse={isResponse}
                preview={preview}
                loading={loading}
                hasCC={showCopy}
                hasBCC={showBlindCopy}
            />
            <EmailAttachedFiles
                attachments={attachments?.fileAttachments}
                {...{
                    toggleClipboardAndAttachment,
                    handleToggleEmailAttachment
                }}
            />
            <EmailActions
                loading={loading}
                sendExternalEmail={sendExternalEmail}
                request={request}
                setRequest={setRequest}
                isDisabled={preview}
                toggleClipboardAndAttachment={toggleClipboardAndAttachment}
            />
        </>
    );
};

export default SendEmail;
