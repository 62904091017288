import {
    CAPSULE_CONTENT_BLOCK_FORMATS,
    contentBlocks,
} from "components/dashboard/KnowledgeBase/CapsuleSettings/enums";
import CapsuleContentBlockHeader from "./CapsuleContentBlockHeader/CapsuleContentBlockHeader";
import ReactQuill from "react-quill";
import "./CapsuleContentBlock.scss";
import ImageView from "components/essentials/ImageView/ImageView";

const { TEXT, RICH_TEXT, VIDEO, PICTURE, GIF, DESCRIPTION, LINK } =
    CAPSULE_CONTENT_BLOCK_FORMATS;

const CapsuleContentBlock = ({
    contentBlock,
    reLoadView,
    hideHeader,
    openDeleteModal,
}) => {
    const { content, contentType, caption, contentFileUrl, contentId } =
        contentBlock;

    const renderBasedOnContentType = () => {
        switch (contentType) {
            case TEXT:
            case DESCRIPTION:
                return <p className='content__body--text'>{content}</p>;

            case RICH_TEXT:
                return (
                    <ReactQuill
                        value={content}
                        readOnly={true}
                        theme={"bubble"}
                    />
                );

            case PICTURE:
            case GIF:
                return (
                    <>
                        <p className='content__body--text'>{caption}</p>
                        <ImageView
                            src={contentFileUrl}
                            alt={caption}
                            className='content__body--responsive__media'
                        />
                    </>
                );

            case VIDEO:
                return (
                    <>
                        <p className='content__body--text'>{caption}</p>
                        <video
                            className='content__body--responsive__media'
                            controls='controls'
                            preload='metadata'>
                            <source src={contentFileUrl} type={"video/mp4"} />
                        </video>
                    </>
                );
            case LINK:
                return (
                    <a
                        href={content}
                        target='_blank'
                        rel='noreferrer noopener'
                        className='content__body--url'>
                        {content}
                    </a>
                );

            default:
                return "";
        }
    };
    return (
        <div className='content__block'>
            <CapsuleContentBlockHeader
                titleText={contentBlocks?.[contentType]?.title}
                hideHeader={hideHeader}
                contentBlock={contentBlock}
                reLoadView={reLoadView}
                openDeleteModal={() => openDeleteModal(contentId)}
            />
            <div className='content__body'>{renderBasedOnContentType()}</div>
        </div>
    );
};

export default CapsuleContentBlock;
