import "./BranchViewEmbeds.scss";
import { Button } from "components/ui";
import ChangeEmbedModal from "./modal/ChangeEmbedModal/ChangeEmbedModal";
import { useEffect, useState } from "react";
import BranchEmbedCapsule from "./BranchEmbedCapsule/BranchEmbedCapsule";

const BranchViewEmbeds = ({
    guideFlowCapsuleClassId,
    guideFlowCapsuleEntryId,
    referenceData,
    handleSuccess,
}) => {
    const [showModal, toggleModal] = useState(false);

    const getCapsuleData = () => {};

    useEffect(() => {
        getCapsuleData();
        // eslint-disable-next-line
    }, [guideFlowCapsuleClassId, guideFlowCapsuleEntryId]);

    return (
        <>
            <div className='branch__content branch-embeds'>
                <div className='branch-embeds__heading'>
                    <h6>Embeds</h6>
                    <Button
                        text='Change'
                        classType='plain'
                        onClick={() => toggleModal(true)}
                        otherClassName={"branch__view--heading__button"}
                    />
                </div>
                <div className='d-flex flex-wrap align-items-center flex-gap mt-2'>
                    {guideFlowCapsuleClassId ? (
                        <BranchEmbedCapsule
                            {...{
                                guideFlowCapsuleClassId,
                                guideFlowCapsuleEntryId,
                            }}
                        />
                    ) : (
                        <p>No Embed Yet</p>
                    )}
                </div>
            </div>
            {showModal && (
                <ChangeEmbedModal
                    showModal={showModal}
                    closeModal={() => toggleModal(false)}
                    handleSuccess={handleSuccess}
                    currentEmbed={{
                        guideFlowCapsuleClassId,
                        guideFlowCapsuleEntryId,
                    }}
                    referenceData={referenceData}
                />
            )}
        </>
    );
};

export default BranchViewEmbeds;
