import React, { useState, useEffect, useContext } from 'react';
import { dataQueryStatus } from 'utils';
import { SocketContext } from 'services/socket/context/socket-v1';
import "./LiveChatStatus.scss";

const statusMapper = {
    [dataQueryStatus.LOADING]: 'Loading',
    [dataQueryStatus.SUCCESS]: 'Active',
    [dataQueryStatus.ERROR]: 'Offline',
};

const LiveChatStatus = () => {
    const [status, setStatus] = useState(dataQueryStatus.IDLE);
    const socket = useContext(SocketContext);

    useEffect(() => {
        const { LOADING, ERROR, SUCCESS } = dataQueryStatus;

        if (!socket) return;

        const handleConnect = () => setStatus(SUCCESS);
        const handleDisconnect = () => setStatus(ERROR);
        const handleConnectError = () => setStatus(ERROR);
        const handleReconnecting = () => setStatus(LOADING);

        socket.on('connect', handleConnect);
        socket.on('disconnect', handleDisconnect);
        socket.on('connect_error', handleConnectError);
        socket.on('reconnect_attempt', handleReconnecting);

        return () => {
            socket.off('connect', handleConnect);
            socket.off('disconnect', handleDisconnect);
            socket.off('connect_error', handleConnectError);
            socket.off('reconnect_attempt', handleReconnecting);
        };
    }, [socket]);

    if (status === dataQueryStatus.IDLE) return "";
    const displayText = statusMapper[status] || 'Idle';

    return (
        <div className='livechat__status'>
            <div className={`circular__indicator ${status}`}></div>
            <span>{displayText}</span>
        </div>
    );
};
export default LiveChatStatus;
