import React, { useEffect, useState } from "react";
import { analyticsRequestTypes } from "components/dashboard/Analytics/AnalyticsTSam/enum";
import { pipeEnums } from "utils/helper";
import {
    ticketClassificationOptions,
    ticketPhaseOptions,
    ticketSolutionTypeOptions,
    ticketSourceOptions,
} from "../enum";
import API from "services/lib/api";
import { apiRoute } from "services";
import { getErrorMessage, generateRandomColor } from "utils/helper";
import SelectUI from "components/ui/SelectUI/SelectUI";
import { teamAnalyticsFilters } from "../../enum";
import {
    setAnalyticsAgents,
    setAnalyticsClassifications,
    setAnalyticsTeams,
} from "store/analytics/actions";
import { useDispatch, useSelector } from "react-redux";

const {
    AGENT,
    TEAM,
    TICKET_CLASSIFICATION,
    TICKET_PRIORITY,
    TICKET_PHASE,
    TICKET_SOURCE,
    TICKET_SOLUTION_TYPE,
} = teamAnalyticsFilters;

const AnalyticsFilterValues = ({
    analyticsRequestType,
    updateRequest,
    options,
    setOptions,
    setLoading,
    setSelectedOptions,
    selectedOptions,
    useCustomDate
}) => {
    const baseLabel = pipeEnums(analyticsRequestTypes[analyticsRequestType]);

    const [isLoading, setIsLoading] = useState(false);
    const [errorMssg, setErrorMssg] = useState("");

    const handleChange = (options) => {
        const reqValues = options?.map(({ value }) => value).toString();
        setSelectedOptions?.(options);
        updateRequest("analyticsRequestValue", reqValues);
    };

    const { analyticsAgents, analyticsTeams, analyticsClassifications } =
        useSelector((state) => state.analytics);

    const dispatch = useDispatch();

    const fetchClassifications = async () => {
        try {
            setIsLoading(true);
            setErrorMssg();
            setLoading?.(true);
            const url = apiRoute.classifications;
            const res = await API.get(url, {
                params: { all: true },
            });

            if (res.status === 200) {
                setIsLoading(false);
                setLoading?.(false);
                const { data } = res.data;

                if (data?.length > 0) {
                    const classifications = data?.map((data) => ({
                        value: data?.classificationId,
                        label: `${data?.name}`,
                        color: generateRandomColor(),
                        data,
                    }));

                    dispatch(setAnalyticsClassifications(classifications));

                    setOptions(classifications);
                    return classifications;
                }
                setOptions([]);
                return [];
            }
        } catch (err) {
            setIsLoading(false);
            setLoading?.(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const fetchUsers = async () => {
        try {
            setIsLoading(true);
            setErrorMssg();
            setLoading?.(true);
            const url = apiRoute.getUsers;
            const res = await API.get(url, {
                params: { all: true },
            });

            if (res.status === 200) {
                setIsLoading(false);
                setLoading?.(false);
                const { data } = res.data;

                if (data?.length > 0) {
                    const users = data?.map((data) => ({
                        value: data?.userId,
                        label: `${data?.firstName} ${data?.lastName}`,
                        color: generateRandomColor(),
                    }));

                    dispatch(setAnalyticsAgents(users));

                    setOptions(users);
                    return users;
                }
                setOptions([]);
                return [];
            }
        } catch (err) {
            setIsLoading(false);
            setLoading?.(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const getTeams = async () => {
        try {
            setIsLoading(true);
            setErrorMssg();
            setLoading?.(true);
            const url = apiRoute?.teams;
            const res = await API.get(url, {
                params: { all: true },
            });

            if (res.status === 200) {
                setIsLoading(false);
                setLoading?.(false);
                const { data } = res.data;

                if (data?.length > 0) {
                    const teams = data?.map((data) => ({
                        value: data?.teamId,
                        label: data?.teamName,
                        color: generateRandomColor(),
                    }));

                    dispatch(setAnalyticsTeams(teams));
                    setOptions(teams);
                    return teams;
                }
                setOptions([]);
                return [];
            }
        } catch (err) {
            setIsLoading(false);
            setLoading?.(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const setCorrespondingValueOptions = () => {
        switch (analyticsRequestType) {
            case AGENT:
                return analyticsAgents?.length > 0
                    ? setOptions(analyticsAgents)
                    : fetchUsers();

            case TEAM:
                return analyticsTeams?.length > 0
                    ? setOptions(analyticsTeams)
                    : getTeams();

            case TICKET_CLASSIFICATION:
                return analyticsClassifications?.length > 0
                    ? setOptions(analyticsClassifications)
                    : fetchClassifications();

            case TICKET_PRIORITY:
                setOptions(ticketClassificationOptions);
                return ticketClassificationOptions;

            case TICKET_PHASE:
                setOptions(ticketPhaseOptions);
                return ticketPhaseOptions;

            case TICKET_SOURCE:
                setOptions(ticketSourceOptions);
                return ticketSourceOptions;

            case TICKET_SOLUTION_TYPE:
                setOptions(ticketSolutionTypeOptions);
                return ticketSolutionTypeOptions;
            default:
                return "";
        }
    };

    const fetchData = async () => {
        // You can await here
        await setCorrespondingValueOptions();
        // ;
        // const response = await MyAPI.getData(someId);
        // ...
    };

    useEffect(() => {
        updateRequest("analyticsRequestValue", "");
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [analyticsRequestType]);

    return (
        <div
            className={`analytics-filter__select-values ${
                useCustomDate ? "has-custom-date" : ""
            }`}>
            <SelectUI
                key={JSON.stringify(options)}
                placeholder={`Select one or more ${baseLabel}(s) to compare`}
                options={options}
                defaultValue={selectedOptions}
                onChange={handleChange}
                isMultiSelection={true}
                hasOptionIcon={true}
                isLoading={isLoading}
                serverError={errorMssg}
                closeMenuOnSelect={false}
                isRequired={true}
                height='40px !important'
                noBorder={true}
                marginClass='mb-0'
            />
        </div>
    );
};

export default AnalyticsFilterValues;
