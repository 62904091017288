import config from "config/config";
import React, { useEffect, useState, createContext } from "react";
import { io } from "socket.io-client";
import { retriveAccessToken } from "storage/cookieStorage";

const token = retriveAccessToken();

export const socket = io(config.socketGateway.SOCKET_BASE_URL, {
    extraHeaders: {
        authorization: token,
    },
    reconnection: true,
    reconnectionDelay: 1000000,
    reconnectionDelayMax: 1000000,
    reconnectionAttempts: 1,
});

export const SocketContext = createContext(socket)

export const reconnectSocket = (authToken) => {
    return io(config.socketGateway.SOCKET_BASE_URL, {
        extraHeaders: {
            authorization: authToken,
        },
        reconnection: true,
        reconnectionDelay: 1000000,
        reconnectionDelayMax: 1000000,
        reconnectionAttempts: 1,
    });
};


export const SocketProvider = props => {
  const [ws, setWs] = useState(socket)

  useEffect(() => {
    const onClose = () => {
      setTimeout(() => {
        setWs(socket)
      }, 3000)
    }

    ws.addEventListener("close", onClose)

    return () => {
      ws.removeEventListener("close", onClose)
    }
  }, [ws, setWs])

  return (
    <SocketContext.Provider value={ws}>{props.children}</SocketContext.Provider>
  )
}

