import { Loading } from "components/ui";
import EmptyInfo from "components/ui/Empty/EmptyInfo/EmptyInfo";
import ErrorView from "components/ui/ErrorView/ErrorView";
import React, { useEffect, useState } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import TicketsPFCollectionsListing from "./TicketsPFCollectionsListing/TicketsPFCollectionsListing";
import "./TicketPostFormCollection.scss";
import PaginationSearch from "components/ui/InputTypes/InputSearch/PaginationSearch/PaginationSearch";

const { IDLE, LOADING, ERROR, NULLMODE, DATAMODE } = dataQueryStatus;

const TicketPostFormCollection = ({ selectedTicket }) => {
    const { ticketId } = selectedTicket || {};
    const [status, setStatus] = useState(LOADING);
    const [errorMssg, setErrorMssg] = useState("");
    const [postFormList, setPostFormList] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    // const [ticketPostForms, setTicketPostForms] = useState([]);
    const [loading, setLoading] = useState(false);

    const getBranchCollections = async (httpRequest) => {
        try {
            setStatus(LOADING);
            setLoading(true);
            // setTicketPostForms([]);
            const url = apiRoute?.getBranchCollections;
            const res = await API.get(url, {
                // signal: httpRequest?.signal,
                params: {
                    search: searchValue,
                },
            });

            if (res.status === 200) {
                const { data } = res.data;
                if (data?.length > 0) {
                    setPostFormList(data);
                    setStatus(DATAMODE);
                } else {
                    setStatus(NULLMODE);
                }

                setLoading(false);
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
            setLoading(false);
        }
    };

    const getTicketPFCollections = async (httpRequest) => {
        try {
            setStatus(LOADING);
            // setTicketPostForms([]);
            if (searchValue?.length > 0) {
                setLoading(true);
            }
            const url = apiRoute?.getTicketPostFormCollections(ticketId);
            const res = await API.get(url, {
                // signal: httpRequest?.signal,
                params: {
                    search: searchValue,
                },
            });

            if (res.status === 200) {
                const { data } = res.data;
                if (data?.length > 0) {
                    setPostFormList(data);
                    // setTicketPostForms(data);
                    setStatus(DATAMODE);
                } else {
                    setStatus(NULLMODE);
                    // getBranchCollections(httpRequest);
                }
                setLoading(false);
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
            setLoading(false);
        }
    };

    const renderBasedOnStatus = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <Loading />;

            case NULLMODE:
                return (
                    <EmptyInfo
                        message={"No Post Form Collections found "}
                        otherClassNames={"ticket__postform--group__empty"}
                    />
                );

            case DATAMODE:
                return (
                    <TicketsPFCollectionsListing
                        data={postFormList}
                        searchValue={searchValue}
                        selectedTicket={selectedTicket}
                    />
                );

            case ERROR:
                return <ErrorView message={errorMssg} />;

            default:
                return "";
        }
    };

    const handlePostFormCollections = (httpRequest) => {
        // ticketPostForms?.length > 0 ||
        // (ticketPostForms?.length === 0 && searchValue?.length === 0)
        //     ? getTicketPFCollections(httpRequest)
        //     : getBranchCollections(httpRequest);

        searchValue?.length === 0
            ? getTicketPFCollections(httpRequest)
            : getBranchCollections(httpRequest);
    };

    useEffect(() => {
        let httpRequest = new AbortController();
        handlePostFormCollections(httpRequest);
        return () => {
            httpRequest.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTicket, searchValue]);

    return (
        <div className=''>
            <div className='ticket__postform--group'>
                <h6>Post Form Collections</h6>
                <PaginationSearch
                    grpClass='ticket__postform--group--search'
                    handleSearch={(search) => setSearchValue(search)}
                    defaultValue={searchValue}
                    isLoading={loading}
                />
                {renderBasedOnStatus()}
            </div>
        </div>
    );
};

export default TicketPostFormCollection;
