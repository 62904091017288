import React, { useState, useEffect } from "react";
import EmailSuccess from "./EmailSuccess/EmailSuccess";
import SendEmail from "./SendEmail/SendEmail";
import { emailModalContentType } from "./enum";
import API from "services/lib/api";
import { apiRoute } from "services";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import ErrorView from "components/ui/ErrorView/ErrorView";
import { Loading } from "components/ui";
import { EXTERNAL_EMAIL_ACTION } from "components/dashboard/AgentInbox/enum";

const { SEND_EMAIL, EMAIL_SUCCESS } = emailModalContentType;
const { ERROR, LOADING } = dataQueryStatus;

const EmailModalContentGroup = ({
    ticketId,
    toggleEmailPreviewModal,
    emailPreviewValue,
    handleSuccess,
    toggleClipboardAndAttachment,
    handleToggleEmailAttachment,
    attachments,
    emailAction,
    setSenderEmailData
}) => {
    const [contentType, setContentType] = useState(LOADING);
    const [externalEmail, setExternalEmail] = useState("");
    const [errorMssg, setErrorMssg] = useState("");

    const getExternalEmail = async () => {
        try {
            setContentType(LOADING);
            setErrorMssg();

            const url = apiRoute.getExternalEmail;
            const res = await API.get(url);

            if (res.status === 200) {
                const { data } = res?.data;

                setExternalEmail(data?.screenName);
                setContentType(SEND_EMAIL);
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setContentType(ERROR);
        }
    };

    useEffect(() => {
        if (
            !emailPreviewValue?.emailType ||
            emailAction === EXTERNAL_EMAIL_ACTION.SEND_NEW_EMAIL
        ) {
            getExternalEmail();
        } else {
            setContentType(SEND_EMAIL);
        }
        //eslint-disable-next-line
    }, []);

    const renderBasedOnContentType = () => {
        switch (contentType) {
            case LOADING:
                return <Loading />;
            case SEND_EMAIL:
                return (
                    <SendEmail
                        ticketId={ticketId}
                        setContentType={setContentType}
                        emailAction={emailAction}
                        emailPreviewValue={emailPreviewValue}
                        externalEmail={externalEmail}
                        handleSuccess={handleSuccess}
                        attachments={attachments}
                        setSenderEmailData={setSenderEmailData}
                        {...{  toggleClipboardAndAttachment, handleToggleEmailAttachment}}
                    />
                );
            case EMAIL_SUCCESS:
                return (
                    <EmailSuccess
                        toggleEmailPreviewModal={toggleEmailPreviewModal}
                    />
                );
            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={getExternalEmail}
                    />
                );
            default:
                return "";
        }
    };
    return <>{renderBasedOnContentType()}</>;
};

export default EmailModalContentGroup;
