import React from "react";
import TicketFilterSelector from "../TicketFilterSelector/TicketFilterSelector";
import { useSelector } from "react-redux";
import { ticketsFilterBuilder } from "../constants";
import { otherCategories } from "components/dashboard/AgentInbox/enum";

const { MENTIONS } = otherCategories;

const MentionsFilter = ({
    countValue,
    label,
    isActive,
    handleFilterChange,
    fetchingCount,
}) => {
    const { userId } = useSelector((state) => state.auth?.user);
    const { incomingTickets } = useSelector((state) => state?.incomingTickets);

    const filterBlock = ticketsFilterBuilder[MENTIONS];

    const filterByMentions = (ticket) => {
        if (ticket?.mentioned) {
            return ticket?.mentioned?.includes(userId);
        }
    };

    const filterByTicketStatus = (ticket) => {
        return ticket.ticketStatus === true && ticket.isNew === true;
    };

    const onlyNewTickets = incomingTickets
        ?.filter(filterByMentions)
        .filter(filterByTicketStatus);

    return (
        <TicketFilterSelector
            countValue={countValue}
            label={label}
            isActive={isActive}
            onClick={handleFilterChange}
            hasNew={onlyNewTickets.length > 0}
            fetchingCount={fetchingCount}
            activeStyles={filterBlock?.activeStyles}
        />
    );
};

export default MentionsFilter;
