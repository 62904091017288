import { Loading } from "components/ui";

import { useEffect, useState } from "react";
import {
    getErrorMessage,
    removeDuplicateObjectsBasedOn2Keys,
} from "utils/helper";
import { dataQueryStatus } from "utils";
import { apiRoute } from "services";
import API from "services/lib/api";
import ErrorView from "components/ui/ErrorView/ErrorView";
import EmptyInfo from "components/ui/Empty/EmptyInfo/EmptyInfo";
import TicketCRListing from "./TicketCRListing/TicketCRListing";

import "./TicketCannedResponses.scss";
import PaginationSearch from "components/ui/InputTypes/InputSearch/PaginationSearch/PaginationSearch";

const { IDLE, LOADING, ERROR, NULLMODE, DATAMODE } = dataQueryStatus;

const TicketCannedResponses = ({ setCannedMessage, selectedTicket }) => {
    const [status, setStatus] = useState(DATAMODE);
    const [errorMssg, setErrorMssg] = useState("");

    const [searchValue, setSearchValue] = useState("");

    const [cannedResponses, setCannedResponses] = useState([]);
    // const [ticketCannedResponses, setTicketCannedResponses] = useState([]);
    const [loading, setLoading] = useState(false);

    const { ticketId } = selectedTicket || {};

    const getAllCannedResponses = async (httpRequest) => {
        try {
            setStatus(LOADING);
            setCannedResponses([]);
            // setTicketCannedResponses([]);
            setLoading(true);
            const url = apiRoute?.formCannedResponses;
            const res = await API.get(url, {
                // signal: httpRequest?.signal,
                params: {
                    search: searchValue,
                },
            });

            if (res.status === 200) {
                const { data } = res.data;
                setLoading(false);

                const cleanedData = removeDuplicateObjectsBasedOn2Keys(
                    data,
                    "title",
                    "subject"
                );

                if (cleanedData?.length > 0) {
                    setStatus(DATAMODE);
                    setCannedResponses(cleanedData);
                } else {
                    setStatus(NULLMODE);
                }
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
            setLoading(false);
        }
    };

    const getTIcketCannedResponses = async (httpRequest) => {
        try {
            setStatus(LOADING);
            // setTicketCannedResponses([]);
            if (searchValue?.length > 0) {
                setLoading(true);
            }
            const url = apiRoute?.getTicketCannedReponses(ticketId);
            const res = await API.get(url, {
                // signal: httpRequest?.signal,
                params: {
                    search: searchValue,
                },
            });

            if (res.status === 200) {
                const { data } = res.data;
                if (data?.length > 0) {
                    setCannedResponses(data);
                    setStatus(DATAMODE);
                    // setTicketCannedResponses(data);
                } else {
                    setStatus(NULLMODE);
                }
                setLoading(false);
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
            setLoading(false);
        }
    };

    const renderBasedOnStatus = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <Loading />;

            case NULLMODE:
                return (
                    <EmptyInfo
                        message={"No Canned Responses found "}
                        otherClassNames={"ticket__postform--group__empty"}
                    />
                );

            case DATAMODE:
                return (
                    <TicketCRListing
                        data={cannedResponses}
                        searchValue={searchValue}
                        setCannedMessage={setCannedMessage}
                    />
                );

            case ERROR:
                return <ErrorView message={errorMssg} />;

            default:
                return "";
        }
    };

    // if ticketCannedResponses is empty and search is there, query all canned responses
    // if ticketCannedResponses is empty and no search, query default
    // if ticketCannedResponses is not empty and search is there or not, search from default
    // ticketCannedResponses is the default canned responses list tailored to the ticket
    const handleCannedResponses = (httpRequest) => {
        // ticketCannedResponses?.length > 0 ||
        // (ticketCannedResponses?.length === 0 && searchValue?.length === 0)
        //     ? getTIcketCannedResponses(httpRequest)
        //     : getAllCannedResponses(httpRequest);

        searchValue?.length === 0
            ? getTIcketCannedResponses(httpRequest)
            : getAllCannedResponses(httpRequest);
    };

    useEffect(() => {
        let httpRequest = new AbortController();
        handleCannedResponses(httpRequest);
        return () => {
            httpRequest.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTicket, searchValue]);

    return (
        <div className='h-100'>
            <div className='ticket__postform--group'>
                <h6>Canned Response</h6>
                <PaginationSearch
                    grpClass='ticket__postform--group--search'
                    handleSearch={(search) => setSearchValue(search)}
                    defaultValue={searchValue}
                    isLoading={loading}
                />
                {renderBasedOnStatus()}
            </div>
        </div>
    );
};

export default TicketCannedResponses;
