import { FOLLOW_UP_TASK_SCHEDULES } from "components/dashboard/AgentInbox/AgentFollowUp/FollowUpTasksView/enum";
import ScheduleDayInput from "components/dashboard/common/ScheduleInput/ScheduleDayInput/ScheduleDayInput";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { Button, Textarea } from "components/ui";
import AddButton from "components/ui/Button/AddButton/AddButton";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import SelectUI from "components/ui/SelectUI/SelectUI";
import { useContext, useEffect, useState } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import {
    convertCommentMentions,
    extractActualTextsFromStringWithLinks,
    getErrorMessage,
} from "utils/helper";
import ValidateForm from "utils/validations/Validator";
import asset from "assets/images";
import { TICKET_AGENT_TASK_STATUSES } from "../../enum";
import { CANNED_RESOURCE_TYPES } from "components/dashboard/TrainSAM/CannedResponses/enum";
import CreateCommentInput from "../../../TicketMentions/CreateComment/CreateCommentInput/CreateCommentInput";

const { PAUSED, RESUME } = TICKET_AGENT_TASK_STATUSES;

const EditTicketAATaskForm = ({
    closeModal,
    handleSuccess,
    selectedTicketTask,
}) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [errorMssg, setErrorMssg] = useState();
    const [request, setRequest] = useState({
        mainInstruction: "",
        dueDate: "",
        initiationSchedule: "",
        recipientIds: [],
        initiationScheduleType: "",
    });

    const isMentionTask =
        selectedTicketTask?.agentUpdateType === CANNED_RESOURCE_TYPES.MENTIONS;

    const [isModified, setIsModified] = useState(false);

    const toastMessage = useContext(ToastContext);

    const handleChange = ({ target: { name, value } }) => {
        setRequest((prevRequest) => ({ ...prevRequest, [name]: value }));
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
        setIsModified(true);
    };

    const handleUpdateStatus = () => {
        const status = !Boolean(request?.paused);
        setRequest((prev) => ({ ...prev, paused: status }));
    };

    const handleUpdateRequest = (name, value) => {
        setRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const handleMentionChange = ({ target: { value } }) => {
        setRequest({ ...request, mainInstruction: value });
        setErrors({ ...errors, mainInstruction: "" });
    };

    const handleTagged = (id) => {
        setRequest((prev) => ({
            ...prev,
            recipientIds: new Set([...prev?.recipientIds, ...id]),
        }));
    };

    const editMessage = async () => {
        try {
            setLoading(true);
            setErrorMssg();

            if (isMentionTask) {
                const { recipientIds } = request;
                const newMentioned = Array.from(recipientIds);
                let newComment = convertCommentMentions(
                    request?.mainInstruction,
                    true
                );
                request.mainInstruction = newComment.trim();
                request.recipientIds = newMentioned;
            }

            const taskStatus = Boolean(request?.paused) ? PAUSED : RESUME;

            const url = selectedTicketTask?.isUpdate ? apiRoute.updateTicketAgentUpdateTask(
                selectedTicketTask?.ticketAgentUpdateId,
                taskStatus
            ) :  apiRoute.updateTicketAgentTask(
                selectedTicketTask?.ticketUpdateTaskId,
                taskStatus
            );
            const cleanedReceipientIds = request?.recipientIds?.flat()?.map((x) => x.replace("#", ""));
            const res = await API.patch(
                url,
                {
                    ...request,
                    recipientIds: cleanedReceipientIds,
                    initiationSchedule: Number(request?.initiationSchedule),
                    isModified,
                }
            );

            if (res.status === 200) {
                const { message } = res.data;
                handleSuccess?.();
                toastMessage(message);
                closeModal();
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request);
        if (formisValid) {
            editMessage();
        } else setErrors(formErrors);
    };

    useEffect(() => {
        if (selectedTicketTask) {
            const {
                modMainInstruction,
                modRecipientIds,
                paused,
                agentUpdate,
                agentUpdateTask,
                isModified,
                isUpdate
            } = selectedTicketTask || {};
            setRequest({
                mainInstruction: isModified ? modMainInstruction : isUpdate ? agentUpdate?.mainInstruction : agentUpdateTask?.mainInstruction,
                recipientIds: isModified ? modRecipientIds : agentUpdateTask?.recipientIds,
                initiationScheduleType: isUpdate ? agentUpdate?.initiationScheduleType : agentUpdateTask?.initiationScheduleType,
                paused,
                initiationSchedule: isUpdate ? agentUpdate?.initiationSchedule : agentUpdateTask?.initiationSchedule,
            });
        }
    }, [selectedTicketTask]);


    const isPaused = Boolean(request?.paused);
    return (
        <form onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMssg)}
                message={errorMssg}
                hide={() => setErrorMssg()}
            />

            {isMentionTask ? (
                <div className="new-followup-form">
                <CreateCommentInput
                    comment={extractActualTextsFromStringWithLinks(
                        request?.mainInstruction
                    )}
                    handleChange={handleMentionChange}
                    handleTagged={handleTagged}
                    autoFocus={false}
                />
                </div>

            ) : (
                <Textarea
                    grpClass='mb-3 mt-3'
                    type='text'
                    name='mainInstruction'
                    label='Add instruction'
                    labelClass='text-dark fw-medium'
                    id='mainInstruction'
                    placeholder='Type instruction'
                    value={request?.mainInstruction}
                    onChange={handleChange}
                    isErr={errors?.mainInstruction}
                    errMssg={errors?.mainInstruction}
                />
            )}

            <ScheduleDayInput
                label='Due Date'
                showLabel={true}
                showSubLabel={false}
                name='initiationSchedule'
                id='initiationSchedule'
                handleDateChange={(totalNoOfSeconds) =>
                    handleUpdateRequest("initiationSchedule", totalNoOfSeconds)
                }
                error={errors?.initiationSchedule}
                timeValue={request?.initiationSchedule}
                maxDays={10}
            />
            <div className='ticketaaresponse__form-flex'>
                <SelectUI
                    name='initiationScheduleType'
                    label=''
                    id='initiationScheduleType'
                    placeholder='When'
                    options={FOLLOW_UP_TASK_SCHEDULES}
                    error={errors?.initiationScheduleType}
                    value={FOLLOW_UP_TASK_SCHEDULES?.find((scheduleType) => {
                        return (
                            request?.initiationScheduleType ===
                            scheduleType.value
                        );
                    })}
                    onChange={({ value }) =>
                        handleUpdateRequest("initiationScheduleType", value)
                    }
                />
                <AddButton
                    btnText={isPaused ? "Continue" : "Pause timer"}
                    customIcon={
                        isPaused
                            ? asset?.icons?.continueTimer
                            : asset?.icons?.pauseTimer
                    }
                    fillIcon={true}
                    type='button'
                    onClick={handleUpdateStatus}
                />
            </div>
            <Button
                type='submit'
                text='Save changes'
                classType='primary'
                loading={loading}
                otherClass='my-3 w-100'
            />
        </form>
    );
};

export default EditTicketAATaskForm;
