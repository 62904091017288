import React, { useState } from "react";
import {
    reconnectSocket,
    socket,
    SocketContext,
} from "services/socket/context/socket";
import { retriveAccessToken } from "storage/cookieStorage";
import ChatTickets from "./ChatTickets/ChatTickets";
import { otherCategories } from "../enum";

const { EXTERNAL_EMAILS } = otherCategories;
const AgentInboxTickets = () => {
    const [showFilters, toggleFilters] = useState(true);
    const [socketConnection, setSocketConnection] = useState(socket);
    const [refreshData, setRefreshData] = useState(0);
    const [ticketData, setTicketData] = useState([]);
    const [selectedTicketHeader, setSelectedTicketHeader] = useState();
    const [showEmail, toggleEmail] = useState(false);
    const [selectedCannedEmail, setSelectedCannedEmail] = useState({});

    const [refetchEmails, toggleRefetchEmails] = useState(false);
    const [emailAction, setEmailAction] = useState("");

    const userToken = retriveAccessToken();

    const handleRefreshData = () => {
        setRefreshData((prevState) => prevState + 1);
    };

    const reconnectUser = () => {
        const socketReconnection = reconnectSocket(userToken);
        setSocketConnection(socketReconnection);
    };

    const handleToggleEmailPreview = (cannedEmail, actionType) => {
        setSelectedTicketHeader(EXTERNAL_EMAILS);
        setSelectedCannedEmail(cannedEmail);
        toggleEmail(true);
        setEmailAction(actionType);
    };

    const handleRemoveEmailPreview = () => {
        setEmailAction();
        setSelectedCannedEmail();
        toggleEmail(false);
    };

    // useEffect(() => {
    //     if (!socket?.connected) {
    //         reconnectUser();
    //     }
    //     // }, [socket, userToken]);
    //     // eslint-disable-next-line
    // }, []);


    return (
        <div className='h-100'>
            <SocketContext.Provider value={socketConnection}>
                <div
                    className={`flexible__layout ${showFilters ? "active--filters" : ""
                        }`}>
                    <ChatTickets
                        reconnectUser={reconnectUser}
                        refreshData={refreshData}
                        handleRefreshData={handleRefreshData}
                        setTicketData={setTicketData}
                        showFilters={showFilters}
                        toggleFilters={toggleFilters}
                        handleToggleEmailPreview={handleToggleEmailPreview}
                        selectedTicketHeader={selectedTicketHeader}
                        setSelectedTicketHeader={setSelectedTicketHeader}
                        refetchEmails={refetchEmails}
                        ticketData={ticketData}
                        toggleEmailPreview={(status = false) => {
                            toggleEmail(status);
                            ;
                            setSelectedCannedEmail();
                        }}
                        handleRemoveEmailPreview={handleRemoveEmailPreview}
                        handleSuccess={() =>
                            toggleRefetchEmails(!refetchEmails)
                        }
                        emailPreviewValue={selectedCannedEmail}
                        emailAction={emailAction}
                        showEmailPreview={showEmail}
                    />
                </div>
            </SocketContext.Provider>
        </div>
    );
};

export default AgentInboxTickets;
