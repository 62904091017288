import React, { useEffect, useState } from "react";
import EmptyInfo from "components/ui/Empty/EmptyInfo/EmptyInfo";
import imageLinks from "assets/images";
import { useSelector, useDispatch } from "react-redux";
import { setActiveTicket, setTicketToLeavePhase } from "store/tickets/actions";
import Ticket from "./Ticket/Ticket";
import { sortTickets } from "utils/helper";
import { socket } from "services/socket/context/socket";
import { CLOSED_TICKET, NEW_TICKET_UPDATE } from "services/socket/events";
import {
    deleteIncomingTicket,
    pushIncomingTicket,
} from "store/tickets/incomingTickets/actions";
import { selectNextTicket } from "./util/helper";
import { setSortedTickets } from "store/tickets/incomingTickets/actions";
import CustomScroll from "components/ui/CustomScroll/CustomScroll";
import "./CustomerTickets.scss";
import { otherCategories } from "components/dashboard/AgentInbox/enum";
import { ISSUE_DISCOVERY } from "../../common/TicketStatus/enum";

const { AGENT_ASSISTANCE } = otherCategories;
const CustomerTickets = ({
    data,
    handleRemoveEmailPreview,
    selectedTicketHeader,
    clearSelectedCustomerMessage,
}) => {
    const dispatch = useDispatch();
    const { ticketsfilterRequest } = useSelector(
        (state) => state.ticketsFilters
    );

    const { agents } = useSelector((state) => state.agents);
    const {
        user: { userId },
    } = useSelector((state) => state.auth);

    const { dateOrder } = ticketsfilterRequest;

    const sortedTickets = sortTickets(data, dateOrder);
    const { activeTicket, ticketToLeavePhase } = useSelector(
        (state) => state.tickets
    );

    const { ticketPhase: selectedTicketPhase } = useSelector(
        (state) => state?.ticketsFilters?.ticketsfilterRequest
    );

    const [trigger, setTrigger] = useState(false);
    const [closedTicket, setClosedTicket] = useState();

    const handleTicketClose = (ticket) => {
        const parsedTicket = JSON.parse(ticket);

        setTrigger(!trigger);
        setClosedTicket(parsedTicket);
    };

    const isTicketOnAgentAssistancePlusIssueDiscoPhase =
        selectedTicketPhase === ISSUE_DISCOVERY &&
        selectedTicketHeader === AGENT_ASSISTANCE;

    const handleTicketUpdate = (updatedTicket) => {
        const parsedTicket = JSON.parse(updatedTicket);
        if (parsedTicket?.newTicketPhase) {
            if (isTicketOnAgentAssistancePlusIssueDiscoPhase) {
                // the goal is to prevent the ticket from leaving the screen if it changes phase while being engaged with a guide
                dispatch(
                    setTicketToLeavePhase({
                        ...parsedTicket,
                        previousPhase: ISSUE_DISCOVERY,
                    })
                );
            } else {
                selectNextTicket(
                    parsedTicket?.newTicketPhase,
                    sortedTickets,
                    (ticket, ticketId) => {
                        dispatch(setActiveTicket(ticket));
                        ticketId &&
                            dispatch(deleteIncomingTicket({ ticketId }));
                        setClosedTicket();
                    }
                );
            }
        }
    };

    useEffect(() => {
        if (
            (activeTicket === null || activeTicket === undefined) &&
            sortedTickets?.length > 0
        ) {
            dispatch(setActiveTicket(sortedTickets[sortedTickets?.length - 1]));
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        dispatch(setSortedTickets(sortedTickets));
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        socket.on(CLOSED_TICKET, handleTicketClose);
        socket.on(NEW_TICKET_UPDATE, handleTicketUpdate);
        return () => {
            socket.off(CLOSED_TICKET);
            socket.off(NEW_TICKET_UPDATE);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (closedTicket?.ticketStatus === false) {
            if (activeTicket?.ticketId === closedTicket?.ticketId) {
                if (isTicketOnAgentAssistancePlusIssueDiscoPhase) {
                    dispatch(
                        setTicketToLeavePhase({
                            ...closedTicket,
                            previousPhase: ISSUE_DISCOVERY,
                        })
                    );
                } else {
                    selectNextTicket(
                        closedTicket,
                        sortedTickets,
                        (ticket, ticketId) => {
                            dispatch(setActiveTicket(ticket));
                            ticketId &&
                                dispatch(deleteIncomingTicket({ ticketId }));
                            setClosedTicket();
                        }
                    );
                }
            }
        }
        //eslint-disable-next-line
    }, [trigger, closedTicket]);

    const handleTicketSelect = (item) => {
        clearSelectedCustomerMessage?.();
        const ticketToLeavePhaseId = ticketToLeavePhase?.ticketId;

        if (ticketToLeavePhaseId && selectedTicketPhase === ISSUE_DISCOVERY) {
            dispatch(
                pushIncomingTicket({ ...ticketToLeavePhase, isNew: true })
            );
            dispatch(deleteIncomingTicket({ ticketId: ticketToLeavePhaseId }));
        }
        dispatch(setActiveTicket(item));
        handleRemoveEmailPreview?.();
    };

    return (
        <>
            {sortedTickets?.length > 0 ? (
                <div>
                    <div>
                        <CustomScroll
                            selector='tickets--list'
                            parentScrollId='allTickets'
                            activeElement={activeTicket?.ticketId}
                            axis='y'
                            className='tickets__list--container'>
                            {sortedTickets?.map((item, index) => {
                                return (
                                    <Ticket
                                        key={index}
                                        data={item}
                                        handleTicketSelect={() =>
                                            handleTicketSelect(item)
                                        }
                                        isActive={
                                            activeTicket?.ticketId ===
                                            item?.ticketId
                                        }
                                        agents={agents}
                                        authUserId={userId}
                                    />
                                );
                            })}
                        </CustomScroll>
                    </div>

                    <div className='empty__chat'>
                        <p>{`You have reached the\n end of this list`}</p>
                    </div>
                </div>
            ) : (
                <EmptyInfo
                    customIcon={imageLinks?.icons?.emptyBuzz}
                    otherClassNames={"with-margin"}
                    message={`No Tickets\n assigned yet`}
                />
            )}
        </>
    );
};

export default CustomerTickets;
