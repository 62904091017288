import { ticketInfoTypes } from "components/dashboard/AgentInbox/enum";
import ErrorView from "components/ui/ErrorView/ErrorView";
import ShinyLoader from "components/ui/ShinyLoader/ShinyLoader";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiRoute } from "services";
import API from "services/lib/api";
import { saveCapsuleClassess } from "store/capsule/actions";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import TicketsInfoElements from "./TicketsInfoElements/TicketsInfoElements";
import { saveTicketsInfoFilters } from "store/ticketsFilters/actions";

const { LOADING, DATAMODE, NULLMODE, ERROR, IDLE } = dataQueryStatus;
const { FORM, CAPSULE } = ticketInfoTypes;

const TicketsInfoFilter = ({
    filters,
    selectedTicketInfo,
    handleTicketInfoSelect,
    refreshData,
    showingFilters,
}) => {
    const [retryCount, updateRetryCount] = useState(0);
    const [errorMssg, setErrorMssg] = useState("");
    const { capsuleClasses } = useSelector((state) => state.capsule);
    const { authTicketInfo } = useSelector((state) => state.ticketsFilters);
    const [status, setStatus] = useState(
        authTicketInfo?.length > 0 ? DATAMODE : IDLE
    );

    const dispatch = useDispatch();

    const { ticketsfilterRequest } = useSelector(
        (state) => state?.ticketsFilters
    );

    const { agentId, search} = ticketsfilterRequest || {};

    const getCapsuleClasses = async () => {
        try {
            setErrorMssg();

            if (retryCount === 0 && capsuleClasses?.length < 1) {
                setStatus(LOADING);
                const url = apiRoute?.getCapsuleClasses;

                const res = await API.get(url);
                if (res.status === 200) {
                    const { data } = res.data;
                    dispatch(saveCapsuleClassess(data));
                    updateRetryCount(1);
                }
            }
            updateRetryCount(1);
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
        }
    };

    const getAuthTicketInfo = async (httpRequest) => {
        try {
            if (authTicketInfo?.length < 1) {
                setStatus(LOADING);
            }
            const res = await API.get(apiRoute?.getAgentTicketsInfoCount, {
                signal: httpRequest?.signal,
                params: {
                    agentId,
                    search
                },
            });

            if (res.status === 200) {
                const { form, capsules } = res.data.data;
                // ticketInfoType, ticketInfoCapsuleClassId, ticketInfoValue, formName, count
                const formInfos = form.map(({ formName, formId }) => ({
                    ticketInfoType: FORM,
                    ticketInfoValue: formId,
                    formName,
                }));

                const capsulesInfos = capsules.map(
                    ({ capsuleClassId, classificationCapsuleEntryId }) => ({
                        ticketInfoType: CAPSULE,
                        ticketInfoCapsuleClassId: capsuleClassId,
                        ticketInfoValue: classificationCapsuleEntryId,
                    })
                );
                dispatch(
                    saveTicketsInfoFilters([...formInfos, ...capsulesInfos])
                );
                setStatus(DATAMODE);
            }
        } catch (err) {
            setErrorMssg(getErrorMessage(err));
            setStatus(ERROR);
        }
    };

    useEffect(() => {
        let httpRequest = new AbortController();

        if (retryCount > 0) {
            getAuthTicketInfo(httpRequest);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        } else {
            getCapsuleClasses(httpRequest);
        }
        return () => {
            httpRequest.abort();
        };
        //eslint-disable-next-line
    }, [capsuleClasses, retryCount, refreshData, agentId]);

    const renderBasedOnStatus = () => {
        switch (status) {
            case LOADING:
                return <ShinyLoader width={`100%`} height={`100%`} />;

            case DATAMODE:
                return (
                    <TicketsInfoElements
                        {...{
                            filters,
                            selectedTicketInfo,
                            handleTicketInfoSelect,
                            authTicketInfo,
                            refreshData,
                        }}
                    />
                );

            case NULLMODE:
                return (
                    <div className='empty__text'>
                        <h6>No Tickets Info available</h6>
                    </div>
                );

            case IDLE:
                return (
                    <div className='empty__text'>
                        <h6>Tickets Infos show here</h6>
                    </div>
                );

            case ERROR:
                return <ErrorView message={errorMssg} />;

            default:
                return "";
        }
    };

    return (
        <div
            className={`${
                !showingFilters ? "" : "mt__filter--container"
            } custom-scroll-bar`}>
            {renderBasedOnStatus()}
        </div>
    );
};

export default TicketsInfoFilter;
