import React, { useState } from "react";
import { useContext } from "react";
import PropTypes from "prop-types";
import { ConfirmAction, Modal } from "components/ui";
import {
    appMessageUserTypes,
    messageTypes,
} from "components/dashboard/AgentInbox/AgentInboxTickets/ChatTickets/ChatModule/LiveChat/MessageBody/Messages/Message/enums";
import { ticketPhases } from "components/dashboard/AgentInbox/enum";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { SocketContext } from "services/socket/context/socket";
import {
    SEND_AGENT_CONVERSATION_REPLY,
    SEND_AGENT_REPLY,
} from "services/socket/events";
import { saveTicketsMessages, setActiveTicket } from "store/tickets/actions";
import {
    deleteIncomingTicket,
    pushIncomingTicket,
} from "store/tickets/incomingTickets/actions";
import { useDispatch, useSelector } from "react-redux";
import { generateID } from "utils/helper";
import { selectNextTicket } from "components/dashboard/AgentInbox/AgentInboxTickets/ChatTickets/CustomerTicketsContainer/CustomerTickets/util/helper";

const { DEFAULT, BRANCH, CANNED_RESPONSE } = messageTypes;
const { ISSUE_DISCOVERY, PROBLEM_CONFIRMATION } = ticketPhases;

const ConfirmTagResponseSend = ({
    showModal,
    closeModal,
    suggestion,
    selectedTicket,
}) => {
    const { item, type } = suggestion || {};
    const { ticketId } = selectedTicket || {};
    const [loading, setLoading] = useState(false);
    const socket = useContext(SocketContext);
    const toastMessage = useContext(ToastContext);

    const { ticketPhase } = useSelector(
        (state) => state?.ticketsFilters?.ticketsfilterRequest
    );
    const { sortedTickets } = useSelector((state) => state.incomingTickets);

    const dispatch = useDispatch();

    const handleNewSuggestion = async () => {
        if (type === DEFAULT || type === CANNED_RESPONSE) {
            const newMessageId = generateID();
            let message = item?.response;
            dispatch(
                saveTicketsMessages({
                    ticketId,
                    senderType: appMessageUserTypes?.WORKSPACE_AGENT,
                    messageContent: message,
                    messageContentId: newMessageId,
                    messageType: type,
                })
            );

            socket.emit(SEND_AGENT_REPLY, {
                ticketId,
                message,
                messageType: type,
            });
        } else {
            if (type === BRANCH) {
                await socket.emit(SEND_AGENT_CONVERSATION_REPLY, {
                    ticketId,
                    conversationId: item?.conversation?.conversationId,
                    messageType: BRANCH,
                });
            }

            if (
                (ticketPhase === ISSUE_DISCOVERY &&
                    selectedTicket?.issueDiscovered) ||
                (ticketPhase !== ISSUE_DISCOVERY && selectedTicket?.assigned)
            ) {
                selectNextTicket(
                    selectedTicket,
                    sortedTickets,
                    (ticket, ticketId) => {
                        dispatch(setActiveTicket(ticket));
                    }
                );
            }

            dispatch(
                pushIncomingTicket({
                    ...selectedTicket,
                    ticketPhase:
                        ticketPhase === ISSUE_DISCOVERY
                            ? PROBLEM_CONFIRMATION
                            : selectedTicket?.ticketPhase,
                    assigned: false,
                })
            );

            if (
                ticketPhase === PROBLEM_CONFIRMATION &&
                item?.conversation?.isSolutionCollection
            ) {
                selectNextTicket(
                    selectedTicket,
                    sortedTickets,
                    (ticket, ticketId) => {
                        dispatch(setActiveTicket(ticket));
                        ticketId &&
                            dispatch(deleteIncomingTicket({ ticketId }));
                    }
                );
            }

            toastMessage(`Conversation sent successfully`);
        }
        setLoading(false);
        closeModal();
    };

    return (
        <Modal show={showModal} close={closeModal}>
            <ConfirmAction
                title={`Are you sure you want to send ${
                    item?.title ||
                    item?.conversation?.issue?.issueName ||
                    "canned response"
                }`}
                handleYesClick={handleNewSuggestion}
                close={closeModal}
                loadingText={`${
                    loading
                        ? `Sending ${item?.conversation?.issue?.issueName}`
                        : ""
                }`}
            />
        </Modal>
    );
};

ConfirmTagResponseSend.prototypes = {
    showModal: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
};

export default ConfirmTagResponseSend;
