import React from "react";
import { appMessageUserTypes, messageTypes } from "./enums";
import MessageOptions from "./MessageOptions/MessageOptions";
import MessageContent from "./MessageContent/MessageContent";
import MessageAttachments from "./MessageAttachments/MessageAttachments";
import MessageTimeStatus from "./MessageTimeStatus/MessageTimeStatus";
import MessageSource from "./MessageSource/MessageSource";
import "./Message.scss";

import MessageStatus from "./MessageContent/MessageStatus/MessageStatus";

const Message = ({
    data,
    openPreviewModal,
    handleResendMessage,
    selectedCustomerMessage,
    selectCustomerMessage=() => null,
}) => {
    const {
        senderType,
        messageType,
        selectedOption,
        branchOptions,
        messageId,
        messageContent,
        messageSource,
        messageContentId,
        fileAttachments,
        readDate,
        deliveryDate,
        status,
    } = data;
    const parsedBranchOptions =
        typeof branchOptions === "string"
            ? JSON.parse(branchOptions)
            : branchOptions;
    const isReceivedMessage =
        senderType !== appMessageUserTypes.WORKSPACE_AGENT;

    const isMessageSelected = selectedCustomerMessage?.messageId === messageId;

    const handleSelectCustomerMessage = () => {
        if (isReceivedMessage) {
            if (isMessageSelected) {
                selectCustomerMessage({});
            } else {
                selectCustomerMessage(data);
            }
        }
    };

    return (
        <div
            id={messageId ? messageId : ""}
            className={`message__group ${isReceivedMessage ? "message-reply" : ""
                } ${isMessageSelected ? "selected" : ""}`}
            onClick={handleSelectCustomerMessage}>

            {fileAttachments?.length > 0 && <MessageAttachments
                fileAttachments={fileAttachments}
                openPreviewModal={openPreviewModal}
                messageSource={messageSource}
            />}
            <MessageContent
                isReceivedMessage={isReceivedMessage}
                messageContent={messageContent}
                messageSource={messageSource}
            />
            {parsedBranchOptions?.length > 0 && (
                <MessageOptions
                    options={parsedBranchOptions}
                    selectedOption={selectedOption}
                    branchOptionId={messageContentId}
                />
            )}

            <div className='message__metadata'>
                {isReceivedMessage && (
                    <MessageSource messageSource={messageSource} />
                )}
                {messageType !== messageTypes?.BRANCH_SUB_SENTENCE && (
                    <>
                        {isReceivedMessage ? (
                            <MessageTimeStatus date={readDate} />
                        ) : (
                            <MessageTimeStatus date={deliveryDate} />
                        )}
                    </>
                )}
            </div>
            {!isReceivedMessage && (
                <MessageStatus
                    status={status}
                    handleResendMessage={() => handleResendMessage?.({}, data)}
                />
            )}
        </div>
    );
};
export default Message;
