import React from "react";
import { Textarea } from "components/ui";
import "./EmailContent.scss";

const EmailContent = ({
    message,
    handleChange,
    isResponse,
    preview,
    loading,
    hasCC,
    hasBCC,
}) => {
    const disableTextarea = preview || loading;

    // let heightReduceCount = 0;

    // heightReduceCount += hasBCC ? 1 : 0;
    // heightReduceCount += hasCC ? 1 : 0;

    // const newHeight =
    //     heightReduceCount === 0
    //         ? "170px"
    //         : heightReduceCount === 1
    //         ? "130px"
    //         : "90px";

    return (
        <div className='email__content'>
            <Textarea
                rows='10'
                name='message'
                id='message-input'
                inputClass='email__content__textarea'
                value={message}
                onChange={handleChange}
                disabled={isResponse ? false : disableTextarea}
            />
        </div>
    );
};

export default EmailContent;
