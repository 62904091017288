import React, { useState, useEffect, useCallback } from "react";
import EmptyInfo from "components/ui/Empty/EmptyInfo/EmptyInfo";
import SpringLoader from "components/ui/SpringLoader/SpringLoader";
import { apiRoute } from "services";
import API from "services/lib/api";
import { dataQueryStatus } from "utils";
import { getErrorMessage } from "utils/helper";
import ErrorView from "components/ui/ErrorView/ErrorView";
import TConversationSuggestionList from "./TConversationSuggestionList/TConversationSuggestionList";
import { InputSearch } from "components/ui";
import { socket } from "services/socket/context/socket";
import {
    appMessageUserTypes,
    messageTypes,
} from "components/dashboard/AgentInbox/AgentInboxTickets/ChatTickets/ChatModule/LiveChat/MessageBody/Messages/Message/enums";
import { RECEIVE_MESSAGE } from "services/socket/events";

const { IDLE, LOADING, ERROR, NULLMODE, DATAMODE } = dataQueryStatus;
const { THIRD_USER } = appMessageUserTypes;
const { DEFAULT } = messageTypes;

const TConversationSuggestions = ({
    lastMessageContent,
    acceptConversation,
}) => {
    const [status, setStatus] = useState(LOADING);
    const [errorMssg, setErrorMssg] = useState("");
    const [conversationSuggestions, setConversationSuggestions] = useState([]);
    const [searchValue, setSearchValue] = useState("");

    const fetchConvoSuggestions = async (lastMessageContent, httpRequest) => {
        try {
            const url = apiRoute?.investigateIssueMessage;
            const res = await API.get(url, {
                params: {
                    search: lastMessageContent?.trim() || "",
                },
                signal: httpRequest?.signal,
            });

            if (res.status === 200) {
                const { data } = res.data;

                let newStatus = data?.length > 0 ? DATAMODE : NULLMODE;

                setStatus(newStatus);

                const structuredData = data?.map((convo) => ({
                    ...convo,
                    issue: {
                        issueName: convo?.issueName,
                    },
                    branchCollection: {
                        branchCollectionId: convo?.branchCollectionId,
                    },
                }));

                setConversationSuggestions(structuredData);
            }
        } catch (err) {
            setStatus(ERROR);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const investigationNewMessage = useCallback((message) => {
        const { messageType, senderType, messageContent } = message;
        if (messageType === DEFAULT && senderType === THIRD_USER) {
            fetchConvoSuggestions(messageContent);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        let httpRequest = new AbortController();

        fetchConvoSuggestions(lastMessageContent, httpRequest);
        socket.on(RECEIVE_MESSAGE, investigationNewMessage);
        return () => {
            httpRequest.abort();
            socket.off(RECEIVE_MESSAGE);
        };
        //eslint-disable-next-line
    }, []);

    const renderBasedOnStatus = () => {
        switch (status) {
            case IDLE:
                return "";

            case LOADING:
                return <SpringLoader />;

            case NULLMODE:
                return (
                    <EmptyInfo
                        message={"No suggested conversations"}
                        otherClassNames='empty__tmtag__conversation'
                    />
                );

            case DATAMODE:
                return (
                    <TConversationSuggestionList
                        conversationSuggestions={conversationSuggestions}
                        acceptConversation={acceptConversation}
                        searchValue={searchValue}
                    />
                );

            case ERROR:
                return (
                    <ErrorView
                        message={errorMssg}
                        handleRetry={fetchConvoSuggestions}
                    />
                );

            default:
                return "";
        }
    };

    return (
        <div className='suggestion__container'>
            <h6>Conversation Suggestions</h6>
            <InputSearch
                id='search'
                name='search'
                placeholder='Search conversations'
                onChange={(search) => setSearchValue(search)}
                value={searchValue}
            />
            <div className='tmt__list--container'>{renderBasedOnStatus()}</div>
        </div>
    );
};

export default TConversationSuggestions;
