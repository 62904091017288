export const ANALYTICS_TSAM_TABLE_HEADERS = [
    "Conversations",
    "Percentage frequency",
    "Actual number count",
    "Success rate",
];

export const ANALYTICS_CAPSULES_TABLE_HEADERS = [
    "Capsule name",
    "Capsule Type",
    "Percentage (tickets)",
];

export const ticketResolutionStatuses = {
    PENDING: "Pending",
    RESOLVED: "Success",
    UN_RESOLVED: "Failed",
};

export const analyticsRequestTypes = {
    AGENT: "AGENT",
    TEAM: "TEAM",
    TICKET_CLASSFICATION: "TICKET_CLASSFICATION",
    TICKET_PHASE: "TICKET_PHASE",
    TICKET_SOURCE: "TICKET_SOURCE",
    TICKET_SOLUTION_TYPE: "TICKET_SOLUTION_TYPE",
    TICKET_CAPSULES: "TICKET_CAPSULES",
};

const {
    AGENT,
    TEAM,
    TICKET_CLASSFICATION,
    TICKET_PHASE,
    TICKET_SOURCE,
    TICKET_SOLUTION_TYPE,
    TICKET_CAPSULES,
} = analyticsRequestTypes;

export const analyticsRequestKeys = {
    [AGENT]: "agentId",
    [TEAM]: "assignedTeamId",
    [TICKET_CLASSFICATION]: "ticketClassification",
    [TICKET_CAPSULES]: "ticketInfoValue",
    [TICKET_PHASE]: "ticketPhase",
    [TICKET_SOURCE]: "ticketSource",
    [TICKET_SOLUTION_TYPE]: "ticketSolutionType",
};
