import CreateCommentInput from "components/dashboard/AgentInbox/AgentInboxTickets/ChatTickets/TicketDetails/TicketMentions/CreateComment/CreateCommentInput/CreateCommentInput";
import { EmailForminput } from "components/dashboard/AgentInbox/AgentInboxTickets/EmailPreview/EmailContentGroup/SendEmail/EmailInput/EmailInput";
import { CANNED_RESOURCE_TYPES } from "components/dashboard/TrainSAM/CannedResponses/enum";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";
import { Button, Input, RichTextInput } from "components/ui";
import ErrorDialog from "components/ui/ErrorDialog/ErrorDialog";
import { useContext, useState } from "react";
import { apiRoute } from "services";
import API from "services/lib/api";
import { getErrorMessage } from "utils/helper";
import ValidateForm from "utils/validations/Validator";

const NewCannedForm = ({
    cannedResourceType,
    isEdit,
    handleSuccess,
    closeModal,
    selectedCannedResource,
}) => {
    const [errorMssg, setErrorMssg] = useState();
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const isCannedEmail =
        cannedResourceType === CANNED_RESOURCE_TYPES.CANNED_EMAIL;
    const isCannedMention =
        cannedResourceType === CANNED_RESOURCE_TYPES.MENTIONS;

    const toastMessage = useContext(ToastContext);

    const [request, setRequest] = useState( selectedCannedResource || {
        cannedResourceSubject: "",
        cannedResourceName: "",
        cannedResourceBody: "",
        cannedResourceType,
        receipients: []
    });

    const handleChange = (e) => {
        const { name, value } = e.target || {};
        setRequest({ ...request, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const handleBodyChange = (value) => {
        setRequest({ ...request, cannedResourceBody: value });
        setErrors({ ...errors, cannedResourceBody: "" });
    };

    const handleMentionChange = ({ target: { value } }) => {
        setRequest({ ...request, cannedResourceBody: value });
        setErrors({ ...errors, cannedResourceBody: "" });
    };

    const handleRecipientEmail = (emails) => {
        setRequest((prev) => ({
            ...prev,
            receipients: emails,
        }));
    }

    const handleTagged = (id) => {
        setRequest((prev) => ({
            ...prev,
            receipients: [...(new Set([...prev?.receipients, ...id]))],
        }));
    };

    const handleMentionDeletion = (id) => {
        let receipients = request.receipients.filter(item => item !== id)
        setRequest({ ...request, receipients });
    }

    const createCannedResource = async () => {
        try {
            setErrorMssg();
            setLoading(true);

            const url = apiRoute.createCannedResource;
            const res = await API.post(url, request);

            if (res.status === 201) {
                const { message } = res.data;
                handleSuccess?.();
                toastMessage(message);
                closeModal();
            }
        } catch (err) {
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const editCannedResource = async () => {
        try {
            setErrorMssg();
            setLoading(true);

            const url = apiRoute.editCannedResource(
                selectedCannedResource?.cannedResourceId
            );
            const res = await API.put(url, request);

            if (res.status === 200) {
                const { message } = res.data;
                handleSuccess?.();
                toastMessage(message);
                closeModal();
            }
        } catch (err) {
            ;
            setLoading(false);
            setErrorMssg(getErrorMessage(err));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const { formisValid, errors: formErrors } = ValidateForm(e, request);

        if (formisValid) {
            isEdit ? editCannedResource() : createCannedResource();
        }

        setErrors(formErrors);
    };

    // const handleRequestInit = () => {
    //     if (isEdit && selectedCannedResource) {
    //         const { receipients } = selectedCannedResource || {};
    //         
    //         

    //         setRequest({
    //             ...selectedCannedResource,
    //             receipients: receipients ? typeof receipients === "string" ? receipients?.split(",") : [] : []
    //         });
    //     }
    // }

    // useEffect(() => {
    //    handleRequestInit()
    //     // eslint-disable-next-line
    // }, [isEdit]);

    return (
        <form className='mt-4' onSubmit={handleSubmit}>
            <ErrorDialog
                show={Boolean(errorMssg)}
                message={errorMssg}
                hide={() => setErrorMssg()}
            />
            <Input
                grpClass='mb-3 mt-3'
                type='text'
                name='cannedResourceName'
                label='Title'
                labelClass='text-dark fw-medium'
                id='cannedResourceName'
                placeholder='Enter title'
                value={request?.cannedResourceName}
                onChange={handleChange}
                isErr={errors?.cannedResourceName}
                errMssg={errors?.cannedResourceName}
                autoFocus={true}
            />
            {isCannedEmail && (
                <>
                    <Input
                        grpClass='mb-3 mt-3'
                        type='text'
                        name='cannedResourceSubject'
                        label='Subject'
                        labelClass='text-dark fw-medium'
                        id='cannedResourceSubject'
                        placeholder='Enter Subject'
                        value={request?.cannedResourceSubject}
                        onChange={handleChange}
                        isErr={errors?.cannedResourceSubject}
                        errMssg={errors?.cannedResourceSubject}
                        autoFocus={true}
                        required={false}
                    />
                    <EmailForminput
                        handleRecipientEmail={handleRecipientEmail}
                        handleRemoveEmail={handleMentionDeletion}
                        recipients={request?.receipients}
                    />
                </>
            )}

            {isCannedMention ? <CreateCommentInput
                comment={request?.cannedResourceBody}
                handleChange={handleMentionChange}
                handleTagged={handleTagged}
                label='Description'
                labelClass='text-dark fw-medium'
                grpClass={'full--input'}
                placeholder='Type Description'
            /> : <RichTextInput
                grpClass='mb-3 mt-3'
                type='text'
                name='cannedResourceBody'
                label='Description'
                labelClass='text-dark fw-medium'
                id='cannedResourceBody'
                placeholder='Type description'
                value={request?.cannedResourceBody}
                onChange={handleBodyChange}
                isErr={errors?.cannedResourceBody}
                errMssg={errors?.cannedResourceBody}
            />
            }
            <Button
                type='submit'
                text='Save and edit update'
                classType='primary'
                loading={loading}
                otherClass='my-3 w-100'
                disabled={request?.cannedResourceBody === ""}
            />
        </form>
    );
};

export default NewCannedForm;
