import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import API from "services/lib/api";
import { ConfirmAction } from "components/ui";
import { getErrorMessage } from "utils/helper";
import { dataQueryStatus } from "utils";
import { Modal } from "components/ui";
import { ToastContext } from "components/dashboard/common/Toast/context/ToastContextProvider";

const { ERROR } = dataQueryStatus;

const ConfirmationModal = ({
    title,
    show,
    closeModal,
    handleSuccess,
    url,
    method,
}) => {
    const [loading, setLoading] = useState(false);
    const expectedStatus =
        method === "delete" || method === "put" || method === "patch" || method === "get"
            ? 200
            : 201;
    const toastMessage = useContext(ToastContext);

    const apiRequest = async () => {
        try {
            setLoading(true);

            const res = await API[method](url);

            if (res?.status === expectedStatus) {
                const { message } = res?.data;

                toastMessage(message);
                closeModal();
                handleSuccess();
            }
        } catch (err) {
            setLoading(false);
            const error = getErrorMessage(err);
            toastMessage(error, ERROR);
        }
    };

    return (
        <Modal show={show} close={closeModal}>
            <ConfirmAction
                title={title}
                loading={loading}
                close={closeModal}
                handleYesClick={apiRequest}
            />
        </Modal>
    );
};

ConfirmationModal.prototypes = {
    title: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    handleSuccess: PropTypes.func.isRequired,
    url: PropTypes.string.isRequired,
    method: PropTypes.oneOf(["delete", "put", "post", "get"]).isRequired,
};

export default ConfirmationModal;
