import React from 'react';
import { ApolloProvider } from "@apollo/client";
import client from "graphql/apolloClient/apolloClient";
import RoutesWithSubroutes from 'routes/RoutesWithSubroutes';
import routes from 'routes/routes';
import { AuthLayout } from 'components/ui';
import { Switch } from 'react-router-dom';
import { LoadingContextProvider } from '../common/LoadingProvider/context';
import ToastContextProvider from '../common/Toast/context/ToastContextProvider';
import { SocketProvider } from 'services/socket/context/socket-v1';

const DashBase = () => {

	return (
		<ApolloProvider client={client}>
			<SocketProvider>
				<LoadingContextProvider>
					<ToastContextProvider>
						<AuthLayout>
							<Switch>
								{RoutesWithSubroutes(routes)}
							</Switch>
						</AuthLayout>
					</ToastContextProvider>
				</LoadingContextProvider>
			</SocketProvider>
		</ApolloProvider>
	);

};


export default DashBase;