import React, { useState } from "react";
import { getTimestampDate } from "utils/helper";
import { Checkbox, Button } from "components/ui";
import { ticketInfoTypes } from "components/dashboard/AgentInbox/enum";
import { senderTypes } from "../../enum";
import { useSelector } from "react-redux";

const { CAPSULE } = ticketInfoTypes;

const TicketEmailAttachmentUpload = ({
    ticketInfos,
    handleToggleEmailAttachment
}) => {
    const { ticketsMessages, activeTicket } = useSelector(
        (state) => state.tickets
    );
    const { ticketId } = activeTicket || {};

    const messages = ticketsMessages?.filter(
        (item) => item?.ticketId === ticketId
    );

    const [ticketInfoAttachments, setTicketInfoAttachments] = useState({
        conversations: [],
        capsules: [],
        filledForm: [],
    });

    const handleConvo = () => {
        const { conversations, capsules, filledForm } = ticketInfoAttachments;

        let text = conversations?.length > 0 ? "\n// Conversations" : "";

        for (let i = 0; i < conversations?.length; i++) {
            text += `\n${
                senderTypes[conversations[i]?.senderType]
            } [${getTimestampDate(conversations[i]?.deliveryDate, "time")}] ${
                conversations[i]?.messageContent
            } ${
                conversations[i]?.fileAttachments?.length > 0
                    ? `\nAttachments: ${conversations[i]?.fileAttachments?.map(
                          ({ fileAttachmentUrl }) => `\n${fileAttachmentUrl}`
                      )}`
                    : ""
            }`;
        }

        text += capsules?.length > 0 ? "\n\n// Capsules" : "";

        for (let i = 0; i < capsules?.length; i++) {
            text += `\n${capsules[i]}`;
        }

        text += filledForm?.length > 0 ? "\n\n// Filled form" : "";

        for (let i = 0; i < filledForm?.length; i++) {
            text += `\nQ: ${filledForm[i]?.formElementLabel} \nA: ${filledForm[i]?.formElementValue} `;
        }

        handleToggleEmailAttachment("ticketAttachment",{
                text,
                knowledgeBase: capsules?.length > 0 || filledForm?.length > 0,
                conversationExchange: conversations?.length > 0,
        });

    };

    const handleAttachConversation = (checked) => {
        if (checked) {
            setTicketInfoAttachments((prev) => ({
                ...prev,
                conversations: messages,
            }));
        } else {
            setTicketInfoAttachments((prev) => ({
                ...prev,
                conversations: [],
            }));
        }
    };

    const handleAttachCapsules = (checked, capsule) => {
        if (checked) {
            setTicketInfoAttachments((prev) => ({
                ...prev,
                capsules: [...prev?.capsules, capsule],
            }));
        } else {
            setTicketInfoAttachments((prev) => ({
                ...prev,
                capsules: prev?.capsules?.filter(
                    (prevCapsule) => prevCapsule !== capsule
                ),
            }));
        }
    };

    const handleAttachForm = (checked, form) => {
        if (checked) {
            setTicketInfoAttachments((prev) => ({
                ...prev,
                filledForm: form,
            }));
        } else {
            setTicketInfoAttachments((prev) => ({
                ...prev,
                filledForm: [],
            }));
        }
    };

    const { conversations, capsules, filledForm } = ticketInfoAttachments;
    const enableUploadBtn =
        conversations?.length > 0 ||
        capsules?.length > 0 ||
        filledForm?.length > 0;

    return (
        <>
            <div className='external-email-ticket-list mt-3'>
                <Checkbox
                    label='Conversation Exchange'
                    id='Conversation Exchange'
                    grpClass={'external-email-ticket-list__item'}
                    inputClass='input-check-grey'
                    onChange={(e) => handleAttachConversation(e.target.checked)}
                />
                {ticketInfos?.map((ticketInfo, i) => {
                    return ticketInfo && (ticketInfo?.ticketInfoType === CAPSULE ? (
                        <Checkbox
                            label={ticketInfo?.content}
                            id={ticketInfo?.content}
                            grpClass={'external-email-ticket-list__item'}
                            inputClass='input-check-grey'
                            onChange={(e) =>
                                handleAttachCapsules(
                                    e.target.checked,
                                    ticketInfo?.content
                                )
                            }
                        />
                    ) : (
                        <Checkbox
                            label='Information from Attached Form'
                            id='id'
                            grpClass={'external-email-ticket-list__item'}
                            inputClass='input-check-grey'
                            onChange={(e) =>
                                handleAttachForm(
                                    e.target.checked,
                                    ticketInfo?.data
                                )
                            }
                        />
                    ))
                })}
            </div>
            <Button
                text='Add Info'
                classType='primary'
                otherClass='w-100'
                disabled={!enableUploadBtn}
                onClick={handleConvo}
            />
        </>
    );
};

export default TicketEmailAttachmentUpload;
