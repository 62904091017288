import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiRoute } from "services";
import API from "services/lib/api";
import {
    RECEIVE_MESSAGE,
    SEND_AGENT_REPLY,
    SUBSCRIBE_TO_TICKET,
    MARK_AS_READ,
} from "services/socket/events";
import {
    clearTicketMessages,
    saveTicketsMessages,
    setTicketMessages,
    updateTicketMessage,
} from "store/tickets/actions";
import { dataQueryStatus } from "utils";
import {
    extractObjectKeyValueFromArray,
    generateID,
    getErrorMessage,
} from "utils/helper";
import LiveChatInput from "./LiveChatInput/LiveChatInput";
import MessageBody from "./MessageBody/MessageBody";
import {
    appMessageUserTypes,
    messageTypes,
} from "./MessageBody/Messages/Message/enums";
import { selectNextTicket } from "../../CustomerTicketsContainer/CustomerTickets/util/helper";
import { deleteIncomingTicket } from "store/tickets/incomingTickets/actions";
import { setActiveTicket } from "store/tickets/actions";
import { MESSAGE_STATUSES, networkStrengths } from "./enum";
import { useSocket } from "services/socket/hook";

const { LOADING, ERROR, DATAMODE } = dataQueryStatus;
const { WORKSPACE_AGENT, THIRD_USER } = appMessageUserTypes;
const { DEFAULT } = messageTypes;

const { OFFLINE, ONLINE, RECONNECTING } = networkStrengths;
const { SENDING, FAILED, SENT, RESENDING } = MESSAGE_STATUSES;

const LiveChat = ({
    setRecentCustomerMessage,
    setTicketData,
    reconnectUser,
    cannedMessage,
    setCannedMessage,
    showFilters,
    setInvestigativeCustomerMessage,
    setFetchMessageStatus,
    selectedCustomerMessage,
    selectCustomerMessage,
}) => {
    const [adjustTextFieldSize, toggleTextFieldSize] = useState(false);
    const { ticketsMessages, activeTicket } = useSelector(
        (state) => state.tickets
    );

    const { sortedTickets } = useSelector((state) => state.incomingTickets);

    const { ticketId, customer } = activeTicket || {};

    const messages = ticketsMessages?.filter(
        (item) => item?.ticketId === ticketId
    );

    // const [status, setStatus] = useState(LOADING);
    const [errorMssg, setErrorMssg] = useState("");
    const [networkStatus, setNetworkStatus] = useState();
    const [unsentMsgs, setUnsetMsgs] = useState([]);

    const socket = useSocket();

    const dispatch = useDispatch();

    // const [disabledInput, setDisabledInput] = useState(false);

    const updateUnsentMsgs = (messageId, shouldDelete) => {
        if (shouldDelete) {
            setUnsetMsgs(unsentMsgs.filter((x) => x !== messageId));
        } else {
            setUnsetMsgs([...unsentMsgs, messageId]);
        }
    };

    const setStatus = (status) => {
        console.log("status", status);
    }

    const requestAllMessages = async (
        httpRequest,
        suspendFetchMessageStatus
    ) => {
        try {
            setStatus(LOADING);
            if (!suspendFetchMessageStatus) {
                setFetchMessageStatus?.(LOADING);
            }
            setErrorMssg();
            const url = apiRoute?.getTicketMessages(ticketId);
            const res = await API.get(url, {
                signal: httpRequest?.signal,
            });
            if (res.status === 200) {
                const { data } = res.data;
                setStatus(DATAMODE);

                const messagesArr = data.map((x) => ({
                    ...x,
                    fileAttachments:
                        x?.fileAttachments?.length > 0
                            ? x?.fileAttachments
                            : x?.form?.formElement?.media?.map((media) => ({
                                fileAttachmentUrl: media?.link,
                                fileAttachmentType: media?.type,
                                fileAttachmentName: media?.mediaName,
                            })),
                }));

                dispatch(setTicketMessages(messagesArr));

                setTicketData(messagesArr);

                let investigativeMessages = extractObjectKeyValueFromArray(
                    data?.filter(
                        (message) =>
                            message?.messageType === DEFAULT &&
                            message.senderType === THIRD_USER
                    ),
                    "messageContent"
                );
                setInvestigativeCustomerMessage(investigativeMessages);

                let recentInputtedMessage = [...data]
                    .reverse()
                    ?.find(
                        (message) =>
                            message.receiverType === WORKSPACE_AGENT &&
                            message.messageType === messageTypes?.DEFAULT
                    );
                setRecentCustomerMessage(recentInputtedMessage);
                setUnsetMsgs([]);
                setFetchMessageStatus?.(DATAMODE);
            }
        } catch (err) {
            console.log(errorMssg)
            setStatus(ERROR);
            setFetchMessageStatus?.(ERROR);
            setErrorMssg(getErrorMessage(err));
            if (err.response?.data?.code === "TICKET_CLOSED") {
                selectNextTicket(
                    activeTicket,
                    sortedTickets,
                    (ticket, ticketId) => {
                        dispatch(setActiveTicket(ticket));
                        // === null ? sortedTickets[0] : ticket
                        ticketId &&
                            dispatch(deleteIncomingTicket({ ticketId }));
                    }
                );
            }
        }
    };

    const sendSocketEvent = (event, data, callBack) => {
        let socketResponse = null;
        let timeOut = null;
        socket.connect();

        const socketEvent = socket.emit(event, { ...data }, (resp) => {
            socketResponse = resp;
            clearTimeout(timeOut);
            return callBack(socketResponse, socketEvent);
        });

        timeOut = setTimeout(() => {
            callBack?.(socketResponse, socketEvent);
        }, 8000);
    };

    const handleNewMessage = async (request, existingMessageData) => {
        let { message, fileAttachments } = request;

        if (message === "" && fileAttachments.length === 0) {
            return "";
        }

        const newMessageId = generateID();

        if (!socket.connected) {
            await socket.connect();
        }

        // message = message?.replace?.(/[^\w ]/g, "");
        const { messageContent, messageId, ...otherData } =
            existingMessageData || {};

        let socReq = {
            ticketId,
            message: messageContent || message,
            messageType: DEFAULT,
            messageContentId: newMessageId,
            fileAttachments,
            ...otherData,
        };

        if (!messageId) {
            dispatch(
                saveTicketsMessages({
                    ticketId,
                    senderType: WORKSPACE_AGENT,
                    messageContentId: newMessageId,
                    messageId: newMessageId,
                    messageContent: message,
                    messageType: DEFAULT,
                    fileAttachments,
                    status: SENDING,
                })
            );
        } else {
            dispatch(
                updateTicketMessage({
                    messageId: messageId,
                    messageContentId: newMessageId,
                    ticketId,
                    status: RESENDING,
                })
            );
        }

        const compMessageId = messageId ? messageId : newMessageId;

        updateUnsentMsgs(compMessageId);

        const socketEventCallBack = (socketResponse, socketEvent, data) => {
            if (socketResponse?.status !== "ok") {
                dispatch(
                    updateTicketMessage({
                        messageId: compMessageId,
                        messageContentId: compMessageId,
                        ticketId,
                        status: FAILED,
                    })
                );
                updateUnsentMsgs(compMessageId);
            } else {
                dispatch(
                    updateTicketMessage({
                        messageId: compMessageId,
                        messageContentId: compMessageId,
                        ticketId,
                        status: SENT,
                    })
                );
                updateUnsentMsgs(compMessageId, true);
            }
        };

        sendSocketEvent(SEND_AGENT_REPLY, socReq, socketEventCallBack);
    };

    const handleMarkAsRead = async (messageId) => {
        await socket.emit(MARK_AS_READ, {
            messageId,
        });
    };

    const createNewMessage = (message, read) => {
        dispatch(
            saveTicketsMessages({
                ...message,
                ticketId: message?.ticket?.ticketId,
                fileAttachments:
                    message?.fileAttachments?.length > 0
                        ? message?.fileAttachments
                        : message?.form?.formElement?.media?.map(
                            (media) => ({
                                fileAttachmentUrl: media?.link,
                                fileAttachmentType: media?.type,
                                fileAttachmentName: media?.mediaName,
                            })
                        ),
                readDate: read && ticketId === message?.ticket?.ticketId &&
                    new Date().toISOString(),
            })
        );
    }

    const handleReceive = (message) => {
        console.log("the message", message);

        if (message.senderType === WORKSPACE_AGENT) {
            if (message?.messageContentId !== "") {
                dispatch(
                    updateTicketMessage({
                        messageContentId: message?.messageContentId,
                        ticketId: message?.ticket?.ticketId,
                        ...message
                    })
                );
            } else {
                createNewMessage(message)
            }
        } else if (
            message?.senderType === THIRD_USER
        ) {
            if (ticketId === message?.ticket?.ticketId) {
                handleMarkAsRead(message?.messageId);
            }
            createNewMessage(message, true)
        }


    };


    const handleSocketStatus = () => {
        const isConnected = Boolean(socket?.connected);
        setStatus(isConnected ? DATAMODE : ERROR);
        setErrorMssg();
        setNetworkStatus(isConnected ? ONLINE : OFFLINE);
        // setDisabledInput(!isConnected);
    };

    const handleReconnectUser = () => {
        setStatus();
        setErrorMssg("");
        if (!socket.connected) {
            socket.connect();
        }
        requestAllMessages()
        reconnectUser();
    };

    useEffect(() => {
        requestAllMessages();
        setCannedMessage?.("");
        return () => {
            dispatch(clearTicketMessages(ticketId));
            // socket.off(CLOSED_TICKET);
        };
        //eslint-disable-next-line
    }, [activeTicket]);

    useEffect(() => {
        socket.emit(SUBSCRIBE_TO_TICKET, { ticketId });
        socket.on(RECEIVE_MESSAGE, handleReceive);
        // socket.on(CLOSED_TICKET, handleTicketClosure);
        socket.on("connect_error", handleSocketStatus);
        socket.on("disconnect", handleSocketStatus);
        socket.on("connect", handleSocketStatus);
        socket.on("error", handleSocketStatus);
        return () => {
            socket.off(RECEIVE_MESSAGE);
            // socket.off(CLOSED_TICKET);
        };
        // eslint-disable-next-line
    }, [socket]);

    useEffect(() => {
        var objDiv = document.getElementById("chatBox");
        objDiv.scrollTop = objDiv.scrollHeight;
    }, [messages]);

    useEffect(() => {
        const httpRequest = new AbortController();
        if (networkStatus === ONLINE && unsentMsgs?.length === 0) {
            requestAllMessages(httpRequest, true);
        }

        if (networkStatus === RECONNECTING) {
            handleReconnectUser();
        }

        return () => {
            httpRequest?.abort();
        };

        //eslint-disable-next-line
    }, [networkStatus]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            !socket.connected && socket.connect()
        }, 3000);

        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [socket])



    return (
        <div
            className={`abs__container ${adjustTextFieldSize ? "size-up" : ""
                } ${!showFilters ? "increase__height" : ""}`}>
            <div
                className={`chat__box  ${!showFilters ? "scrollable-collasped-y" : "scrollable-y"
                    }`}
                id='chatBox'>
           
                <MessageBody
                    {...{
                        messages,
                        customer,
                        selectedCustomerMessage,
                        selectCustomerMessage,
                    }}
                    handleResendMessage={handleNewMessage}
                />
            </div>
            <LiveChatInput
                {...{
                    ticketId,
                    handleNewMessage,
                    cannedMessage,
                    setCannedMessage,
                    adjustTextFieldSize,
                    toggleTextFieldSize,
                }}
                // disabledInput={disabledInput || status === ERROR}
            />
        </div>
    );
};

export default LiveChat;